import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  contactNumber: '',
  city: '',
  country: '',
  experiences: [],
  educationalBackground: [],
  professionalHeadlines: [],
  characterReferences: [],
}

const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload
    },
    setMiddleName: (state, action) => {
      state.middleName = action.payload
    },
    setLastName: (state, action) => {
      state.lastName = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setContactNumber: (state, action) => {
      state.contactNumber = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
    },
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setExperiences: (state, action) => {
      state.experiences = [...action.payload].sort((a, b) => {
        const dateA = new Date(a.experienceFrom).valueOf() || 0
        const dateB = new Date(b.experienceFrom).valueOf() || 0
        return dateB - dateA
      })
    },
    setEducationalBackground: (state, action) => {
      state.educationalBackground = [...action.payload].sort((a, b) => {
        const dateA = new Date(a.from).valueOf() || 0
        const dateB = new Date(b.from).valueOf() || 0
        return dateB - dateA
      })
    },

    setProfessionalHeadlines: (state, action) => {
      state.professionalHeadlines = action.payload
    },
    setCharacterReferences: (state, action) => {
      state.characterReferences = action.payload
    },
  },
})

export const {
  setFirstName,
  setMiddleName,
  setLastName,
  setEmail,
  setContactNumber,
  setCity,
  setCountry,
  setExperiences,
  setEducationalBackground,
  setProfessionalHeadlines,
  setCharacterReferences,
} = applicantSlice.actions

export default applicantSlice.reducer
